.option {

  &:hover{
    cursor: pointer;
  }

  &__label {
    padding-left: 0;

    &--no-icon {
      padding-left: 70px;
    }
  }
}