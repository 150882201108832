.detail-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 24px;
  margin-bottom: 20px;

  &--has-action {
    grid-template-columns: 4fr 4fr 3fr;
  }

  &--full-width {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: 153px 1fr;

    @media (max-width: 759px) {
      grid-template-columns: 4fr 7fr;
    }
  }

  &__label {
    flex: 4;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: #636E7B;
    width: 100%;
    text-transform: capitalize;
    padding-right: 20px;
  }

  &__value {
    flex: 7;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #39393C;
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;
    padding-right: 20px;
    box-sizing: border-box;
    padding-top: 2px;

    &--has-action {
      flex: 4;
    }
  }

  &__value-row {
    line-height: 16px;
    display: block;
    width: 100%;
  }

  &__action-container {
    flex: 3;
    text-align: right;
  }
}
