$--title: --title;
$--subtitle: --subtitle;
$--link: --link;
$--backgroundColor: --backgroundColor;
$--card: --card;
$--secondary-card: --secondary-card;
$--primary: --primary;
$--secondary: --secondary;
$--text-on-primary: --text-on-primary;
$--icon: --icon;
$--loading: --loading;

$theme-map-light: (
    $--title: #39393C,
    $--subtitle: #636363,
    $--link: #008FFF,
    $--backgroundColor: #F0F0F0,
    $--card: #fff,
    $--secondary-card: #F8F9FA,
    $--primary: #008FFF,
    $--secondary: #F0F0F0,
    $--text-on-primary: #fff,
    $--icon: #8695A7,
    $--loading: #d9e0e6,
);