@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.skeleton {
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

p.skeleton {
    display: block;
    border-radius: 8px;
    background-color: theme-var($--secondary);
    width: 100%;
    height: 10px;
}

div.skeleton {
    height: 10px;
    width: 100%;
    border-radius: 4px;
    background-color: theme-var($--loading);
}