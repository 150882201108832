#pagination-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    p {
        font-size: 14px;
    }

}

#record-count-container {
    display: flex;
}

#pagination-buttons {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: flex-end;
    p {
        text-align: center;
    }
}

.pagination_button {
    border: none;
    background: none;
    outline: none;
    svg {
        fill: #8695A7;
        width: 15px;
        height: 15px
    }
    
}

.pagination_button:disabled {
    svg {
        fill: #c3cdd8;
    }
}

.pagination_button:hover:enabled {
    cursor: pointer;
    svg {
        fill: #9badc2; 
    }
}

.pagination_button:active:enabled {
    svg {
        fill: #606b78; 
    }
}