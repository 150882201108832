@import '../../../styles/theme/mixins';
@import '../../../styles/theme/variables';



#homepage-container {
    display: grid;
    justify-items: center;
    grid-template-rows: .5fr min-content min-content 50px;
    row-gap: 2em;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    grid-template-areas:
    "search"
    "registrars"
    "about"
    "footer";
    scrollbar-width: none;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;


    h1 {
        font-size: clamp(24px, 5vw, 32px);
        font-weight: 400;
        color: theme-var($--title)
    }
    h2 {
        font-size: clamp(18px, 5vw, 24px);
        color: theme-var($--title);
        font-weight: 400;
        height: 32px;
        text-align: center;
        margin-bottom: 1em;
    }
}

#homepage-container::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
}

#search-section {
    grid-area: search;
    display: grid;
    row-gap: 16px;
    justify-items: center;
    grid-template-rows: auto;
    margin-left: 1em;
    margin-right: 1em;

    a {
        text-align: center;
        width: 100%;
    }
}

#registrar-section {
    grid-area: registrars;
    width: 100%;
    display: grid;
    justify-items: stretch;
    grid-template-rows: .1fr 1fr;
    row-gap: 1em;
    #registrars {
        margin: 0 1em 0 1em;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        row-gap: 1em;
        column-gap: 1em;
        justify-items: center;
    }
}

#about {
    grid-area: about;
}