footer {
    display: grid;
    grid-template-columns: 100px min-content min-content min-content;
    grid-template-areas: "poweredBy links links links";
    column-gap: 2em;
    align-items: center;
    justify-items: center;
    p {
        font-size: 14px;
        grid-area: poweredBy;
    }
    img {
        height: 32px;
        grid-area: links;
    }
}

@media (max-width:450px) {
    footer {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 
        "poweredBy poweredBy poweredBy"
        "links links links";
        
    }
}