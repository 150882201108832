@import '../../../../styles/theme/variables';
@import '../../../../styles/theme/mixins';

.loading-list {
  display: grid;
  row-gap: 1em;
  max-height: 600px;
  max-width: 720px;
  width: 100%;
  overflow-y: scroll;
  margin-top: 5vh;

  &::-webkit-scrollbar {
    background: transparent;
  }
}
