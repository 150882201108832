@import '../../../../styles/theme/variables';
@import '../../../../styles/theme/mixins';

.loading-card {
  background-color: theme-var($--card);
  max-width: 720px;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 4fr;
  column-gap: 1em;
  row-gap: 1em;
  padding: 16px;
  box-sizing: border-box;
}