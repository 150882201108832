@import './theme/variables';
@import './theme/mixins';
@import '../components/library/Skeleton/Skeleton';

@font-face {
    font-family: 'Roboto';
    src: url('./theme/fonts/Roboto-Regular.ttf')
};

body {
    height: 100vh;
    font-family: 'Roboto';
    margin: 0;
    background-color: theme-var($--backgroundColor);
}

a {
    color: theme-var($--link);
    text-decoration: none;
}

#app-container {
    display: grid;
    grid-template-rows: 64px auto;
    grid-template-columns: 1fr;
    grid-template-areas:
    "header"
    "content";
    height:100vh;
}

main {
    grid-area: content;
    height: 100%;

}

.card {
    background-color: theme-var($--card);
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
}

.outlined-button {
    background: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 0.5px;
    padding: 8px 16px 8px 16px;
    width: fit-content;
    border-color: theme-var($--primary);
    color: theme-var($--primary);
}

.outlined-button:hover:enabled {
    cursor: pointer;
    color: theme-var($--text-on-primary);
    background-color: theme-var($--primary);
}

.link-button {
    color: theme-var($--link);
    background: none;
    outline: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

footer {
    grid-area: footer;
    align-items: center;
}

hr {
    border-style: solid;
    border: none;
    border-top: 2px solid #EAECEE;
    border-radius: 4px;
}

