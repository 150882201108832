.document-summary {
  &__header {
    display: grid;
    grid-template-columns: 4fr 4fr 3fr;
    align-items: center;
    column-gap: 1em;
  }

  &__id-label {
    min-width: 125px;
  }

  &__id-value {
    word-break: break-all;
    color: #39393C;
    font-weight: 400;
  }

  &__link {
    text-align: right;
    font-size: 12px;
  }

  &__recorder-label {
    align-items: center;
  }
}