@import '../../../styles/theme/mixins';
@import '../../../styles/theme/variables';

.searchbar-outline {
  display: flex;
  height: 40px;
  width: 100%;
  border: 1.5px solid #EAECEE;
  border-radius: 4px;
  align-items: center;
  position: relative;

  &__label {
    position: absolute;
    color: #65778B;
    background-color: theme-var(--card);
    pointer-events: none
  }

  &__input {
    width: 85%;
    font-size: 15px;
    border: none;
    margin-left: 1em;
    background: none;

    &::placeholder {
      color: transparent;
    }

    &:focus {
      outline: none;
    }
  }

  &__icon {
    height: 16px;
    fill: theme-var($--icon);

    &:hover {
      cursor: pointer;
    }
  }
}

.searchbar-outline:focus-within {
  border: 2px solid theme-var($--link);

  label {
    color: theme-var($--link);
  }
}

.searchbar-outline:hover:not(:focus-within) {
  border: 1.5px solid #C2C3C6;
}