@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.search-list {
  width: 900px;
  max-width: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    height: 80px;
  }

  &__registrar {
    display: flex;
    height: 60px;
    align-items: center;
  }

  &__registrar-logo {
    height: 100%;
    margin-right: 1em;
  }

  &__dropdown-container {
    display: flex;
    align-items: center;
    min-width: 240px;
  }

  &__dropdown{
    background: theme-var($--secondary-card);
  }

  &__option-list{
    min-width: min-content;
  }

  &__records-container {
    display: grid;
    grid-template-rows: 1fr 20px;
    justify-items: center;
    max-width: 100%;
    row-gap: 16px;
    width: 100%;
    box-sizing: border-box;

    .card {
      padding: 16px 24px 16px 24px;
    }

    h3 {
      font-size: 1.5em;
      margin-top: 20vh;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      max-height: calc(100vh - 340px);
      width: 100%;
      position: relative;
    }
  }
}