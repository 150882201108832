@import '../../../styles/theme/mixins';
@import '../../../styles/theme/variables';

.featured-registrar {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  background: theme-var(--secondary-card);
  padding: 8px 16px;
  margin-top: 20px;
  border-radius: 4px;

  &__image-container {
    padding: 12px;
    max-width: (100% / 3);
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__summary {
    line-height: 20px;
    padding: 12px;
    font-size: 14px;
  }
  &__summary-title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__summary-description{
    margin-bottom: 8px;
    color: theme-var(--subtitle)
  }
}