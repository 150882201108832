.record-summary {
  flex: 1;
  width: 800px;
  max-width: 100%;

  &__header {
    margin: 40px 0 0 0;
  }
}

#record-root {
  z-index: 1;
  max-width: 800px;
  width: 100%;
  grid-area: resultContent;
}

#record-details-back {
  display: flex;
  align-items: center;
  margin: 27px 0 27px 0;

  svg {
    height: 2em;
    fill: #8695A7;
  }
}

.record-loading {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 400px 300px;
  row-gap: 1em;
}

.record-loading-body {
  display: grid;
  margin-right: 40%;
  grid-template-columns: 3fr 7fr;
  grid-template-rows: repeat(auto-fit, min-content);
  row-gap: 1em;
  column-gap: 1em;

  hr {
    grid-column: -1/1;
    padding-right: 60%;
  }
}

.record-loading-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
}

.record-loading-footer {
  display: grid;
  grid-template-columns: 1fr;
}