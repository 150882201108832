@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.tab-controller {
  &__item {
    text-transform: capitalize;
  }
}

ul.tabs-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #CCC;
  margin-top: 0;
  padding: 0;
  overflow: visible;

  li {
    display: block;
    color: #000;
    text-align: center;
    padding: 16px;
    position: relative;
    cursor: pointer;
    margin-bottom: 4px;
    list-style: none;

  }

  li:first-child {
    padding-left: 0;

    .outline {
      margin-left: 0;
    }
  }

  .outline {
    position: absolute;
    background-color: theme_var($--card);
    bottom: -10%;
    width: 100%;
    margin-left: -16px;
    border-radius: 4px;
    height: 4px;
    translate: 0;
  }
}

ul.tabs-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
  overflow: visible;

  li {
    color: #000;
    padding: 16px;
    position: relative;
    cursor: pointer;
    margin-bottom: 1px;
    margin-left: 0.5em;
    border-bottom: 1px solid #EAECEE;
    list-style: none;
  }

  .outline {
    position: absolute;
    background-color: theme_var($--card);
    left: -1%;
    bottom: 5px;
    height: 70%;
    width: 4px;
    border-radius: 4px;
  }
}

.layout-1 {
  display: grid;
  grid-template-rows: min-content auto;
  row-gap: 1em;
  height: 100%;
}

.layout-2 {
  display: grid;
  grid-template-columns: 3.5fr 6fr;
  column-gap: 2em;
  height: 100%;

  ul {
    background-color: theme-var($--secondary-card);
    border-radius: 4px;
    padding: 4px 24px 4px;
    height: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 670px) {
  .layout-2 {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    row-gap: 1em;
  }

  ul.tabs-vertical {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: theme-var($--card);
    border-bottom: 1px solid #CCC;
    margin-top: 0;
    overflow: visible;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;

    li {
      display: inline;
      color: #000;
      text-align: center;
      padding: 16px;
      position: relative;
      cursor: pointer;
      margin-bottom: 4px;
      border-bottom: 0;
    }

    li:first-child {
      padding-left: 0;
      margin-left: 0;

      .outline {
        margin-left: 0;
      }
    }

    .outline {
      position: absolute;
      background-color: theme_var($--card);
      bottom: -10%;
      width: 100%;
      margin-left: -16px;
      border-radius: 4px;
      height: 4px;
    }
  }
}

#tabcontroller-content {
  height: 100%;
}