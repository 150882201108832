.labeled-list {
  display: grid;
  grid-template-columns: 4fr 7fr;
  font-size: 14px;
  color: #39393C;
  column-gap: 1em;

  p {
    font-weight: 400;
  }

  p:first-child {
    margin-top: 0;
  }

  &__label {
    margin-top: 0;
    font-weight: 700;
    text-transform: capitalize;
  }
}