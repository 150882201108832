@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.navbar {
  background-color: #FFF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 2;

  &__logo-container {
    height: 32px;
    padding-left: 32px;
  }

  &__logo {
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &__environment {
    padding: 0 20px;
    color: theme-var($--icon);
    font-size: 12px;
    text-align: right;
  }
}