@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.registrar-header {
  display: flex;
  height: 60px;
  align-items: center;

  &__logo {
    height: 100%;
    margin-right: 1em;
  }
}