@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.registrar-tile {
  width: 100%;
  background-color: theme-var($--card);
  display: grid;
  grid-template-rows: 133px 55px;
  border-radius: 4px;

  &__image-container {
    position: relative;
    max-height: 133px;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    max-height: 133px;
    background-size: cover;
    border-radius: 4px 4px 0 0;
    filter: brightness(60%);
  }

  &__name {
    position: absolute;
    color: #FFF;
    bottom: 0;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 700;
  }

  &__learn-more {
    display: grid;
    grid-template-columns: 9fr 1fr;
    align-items: center;
    margin-left: 16px;
    height: 48px;
  }

  &__arrow {
    height: 30%;
    fill: #8695A7;
  }
}