@import '../../../styles/theme/mixins';
@import '../../../styles/theme/variables';

#searchbar {
    display: grid;
    grid-template-columns: .5fr 8fr 1fr;
    column-gap: 11px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.24);
    height: 50px;
    align-items: center;
    border-radius: 4px;
    max-width: 624px;
    .icon {
        fill: theme-var($--icon);
        padding-left: 16px;
        width: 1.2em;
        font-size: 1.2em;
    }
    input {
        border: none;
        font-size: 14px;
        
    }
    input:focus {
        outline: none;
    }
    button {
        border: none;
        height: 100%;
        border-radius: 0 4px 4px 0;
        background-color: theme-var($--primary);
        width: 84px;
        color: theme-var($--text-on-primary);
        cursor: pointer;
    }

}