#search-container {
  display: grid;
  justify-items: center;
  grid-template-columns: 348px auto;
  grid-template-rows: 9.5fr 50px;
  column-gap: 1em;
  row-gap: 1em;
  grid-template-areas:
    "sidebar content"
    "sidebar footer";
}

#search-container::-webkit-scrollbar {
  background: transparent;
  width: 0;
}

#sidebar {
  grid-area: sidebar;
  background-color: #FFF;
  row-gap: 1em;
  padding: 16px;
  padding-top: 24px;
  display: grid;
  grid-auto-rows: min-content;
  place-items: center;
  box-sizing: border-box;
  width: 100%;

  p {
    color: #65778B;
    justify-self: flex-start;
    margin: 0;
  }
}

#results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: auto;

  h2 {
    color: #39393C;
    font-weight: 400;
  }
}

#results {
  overflow-y: scroll;
  display: grid;
  row-gap: 16px;
  width: 100%;
  grid-area: resultContent;

  ::-webkit-scrollbar {
    display: block;
  }

  -webkit-overflow-scrolling: touch;
}

main {
  grid-area: content;
  overflow-y: scroll;
}

@media (max-width: 1000px) {
  #search-container {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto 50px;
    row-gap: 2em;
    grid-template-areas:
        "sidebar"
        "content"
        "footer";

    h2 {
      margin-top: 2vh;
    }
  }

  #results-container {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
  #sidebar {
    width: 100%;
    max-width: 720px;
    border-radius: 0 0 4px 4px;
  }
}