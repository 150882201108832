.search-result {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    max-width: 100%;
    column-gap: 1em;
    overflow-x: auto;
    height: fit-content;

    button:last-child {
        grid-column: 1/-1;
    }
}
