.checkbox {
  color: #65778B;
  display: flex;
  flex-direction: row;
  height: 30px;

  &--hover {
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__box {
    display: flex;
    width: 15px;
    height: 15px;
    border: 1px solid #EAECEE;
    border-radius: 5px;
    margin-right: 10px;
    transition: all .2s ease;

    &--hover {
      background: #EAECEE;
    }

    &--checked {
      background: darken(#EAECEE, 20);
    }
  }
}