@import '../../../styles/theme/variables';
@import '../../../styles/theme/mixins';

.select {
  width: 100%;
  height: 50px;
  position: relative;
  color: #65778B;
  border-radius: 4px;

  &__button {
    color: #65778B;
    border-radius: 4px;
    width: 100%;
    background: none;
    height: 100%;
    border: 1.5px solid #EAECEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    padding-left: 10px;
    position: relative;

    &:hover {
      border: 1.5px solid #C2C3C6;
    }
    &:after {
      font-size: 1.2em;
    }
  }

  &__icon {
    height: 30px;
    padding: 8px 18px 8px 8px;
  }

  &__label{
    display: flex;
    flex: 1;
  }

  &__arrow {
    display: flex;
    flex: 1;
    max-width: 10px;
    padding: 0 10px;
    fill: theme-var($--icon);
    width: 10px;
  }

  &__option-list {
    background-color: theme-var($--card);
    border-radius: 4px;
    overflow-y: hidden;
    position: absolute;
    margin-top: 3px;
    width: 100%;
    padding: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 3;

    li {
      height: 40px;
      padding: 8px;
      margin-bottom: 1px;
      display: flex;
      align-items: center;

      img {
        height: 80%;
        margin-right: 8px;
        width: 60px;
        object-fit: contain;
      }
    }

    li:hover {
      background-color: #F8F9FA;
    }
  }
}